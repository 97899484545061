import * as PropTypes from "prop-types";

import styles from "@platformAssets/components/RadioButton.module.scss";

const RadioButton = ({
  disabled = false,
  value,
  name,
  label,
  id,
  isSizeMedium = false,
  isSizeSmall = false,
  isSelected,
  isForm,
  cb,
  customClass,
}) => {
  let classNames = isSizeMedium
    ? `${styles.radioWrapper} ${styles.medium}`
    : isSizeSmall
      ? `${styles.radioWrapper} ${styles.small}`
      : isForm && isSelected
        ? `${styles.radioWrapper} ${styles.borderCheck}`
        : isForm
          ? `${styles.radioWrapper} ${styles.border}`
          : styles.radioWrapper;

  const handleChange = (e) => {
    if (cb && typeof cb === "function") {
      cb(e.target.value);
    }
  };
  return (
    <div className={`${classNames} ${customClass ?? ""}`}>
      <input
        type="radio"
        name={name}
        // defaultChecked={checkedValue}
        checked={isSelected}
        disabled={disabled}
        onChange={handleChange}
        value={value}
        id={id}
      />
      <label htmlFor={id}>{label && <span>{label}</span>}</label>
    </div>
  );
};

RadioButton.propTypes = {
  disabled: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
  isSelected: PropTypes.bool,
  switcherSmall: PropTypes.bool,
  customClass: PropTypes.any,
  id: PropTypes.any,
  isSizeMedium: PropTypes.bool,
  isSizeSmall: PropTypes.bool,
  cb: PropTypes.func,
};

export default RadioButton;
